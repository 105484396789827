import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Secondary/Filters'
import ContactIntro from 'components/layout/Sections/Contact/Intro'
import FormSection from 'components/layout/Sections/Contact/FormSection'

import { CONTACT_LINKS } from 'constants/links'

import type { ContactTechnicalServicePageQuery } from 'types/graphql'

const ContactTechnicalService: React.FC<
  PageProps<ContactTechnicalServicePageQuery>
> = ({ data }) => {
  const { page } = data

  const PAGE = page?.pageTechnicalCustomerService
  const PAGE_SEO = page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
      <Header
        title={PAGE.technicalCustomerServiceS1Title!}
        image={{
          src: PAGE.technicalCustomerServiceS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.technicalCustomerServiceS1Img?.altText!,
        }}
      />
      <main>
        <HeaderFilters options={CONTACT_LINKS} />
        <ContactIntro
          title={PAGE.technicalCustomerServiceS2Title!}
          description={PAGE.technicalCustomerServiceS2Description!}
          icon={PAGE.technicalCustomerServiceS2Img?.localFile?.publicURL!}
        />
        <FormSection
          title={PAGE.technicalCustomerServiceS3Title!}
          formID="technical-service-form"
        />
      </main>
    </Layout>
  )
}

export default ContactTechnicalService

export const query = graphql`
  query ContactTechnicalServicePage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/serwis-techniczny/" }
    ) {
      seo {
        ...WpSEO
      }
      pageTechnicalCustomerService {
        technicalCustomerServiceS1Title
        technicalCustomerServiceS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        technicalCustomerServiceS2Title
        technicalCustomerServiceS2Description
        technicalCustomerServiceS2Img {
          localFile {
            publicURL
          }
        }
        technicalCustomerServiceS3Title
      }
    }
  }
`
